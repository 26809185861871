<template>
  <div class="customer-redirect">
    <div class="container-outer container-outer_width-720 customer-redirect__container">
      <div class="content-block content-block_transparent customer-redirect__center-block">
        <div class="content-block__body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-lg-12">
                <div class="customer-redirect__message">
                  <div class="customer-redirect__icon">
                    <slot name="icon">
                      <svg class="icon icon-warning_100">
                        <use xlink:href="#icon-warning_100"></use>
                      </svg>
                    </slot>
                  </div>
                  <h1 class="h1 customer-redirect__h1">{{ title }}</h1>
                  <div class="row">
                    <div class="col-12 col-lg-5">
                      <p class="content-block__text">{{ text }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <slot name="button">
                  <router-link to="/" class="button">Перейти на главную</router-link>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '404',
  computed: {
    title () {
      return this.$route.params.title ? this.$route.params.title : 'Страница не существует'
    },
    text () {
      return this.$route.params.text ? this.$route.params.text : 'Похоже, что страница которую вы ищете не существует или вы не можете её просматривать.'
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-redirect {
  padding-top: 0;
}
</style>
